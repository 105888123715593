@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
}

.bg-top-pattern {
  background-image: url("data:image/svg+xml,%3Csvg width='360' height='190' viewBox='0 0 360 190' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M0 0H360V165C360 178.807 348.807 190 335 190H25C11.1929 190 0 178.807 0 165V0Z' fill='url(%23paint0_linear_15_19)'/%3E%3Cpath opacity='0.5' d='M0 0H360V165C360 178.807 348.807 190 335 190H25C11.1929 190 0 178.807 0 165V0Z' fill='url(%23paint1_linear_15_19)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_15_19' x1='5.36442e-06' y1='190' x2='360' y2='1.09798e-06' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%238B6AAE' stop-opacity='0.75'/%3E%3Cstop offset='0.215817' stop-color='%238B6AAE' stop-opacity='0.25'/%3E%3Cstop offset='0.217259' stop-color='%238B6AAE' stop-opacity='0.5'/%3E%3Cstop offset='0.649188' stop-color='%238B6AAE' stop-opacity='0.5'/%3E%3Cstop offset='0.651564' stop-color='%238B6AAE' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%238B6AAE'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_15_19' x1='5.36442e-06' y1='190' x2='360' y2='1.09798e-06' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%238B6AAE' stop-opacity='0.75'/%3E%3Cstop offset='0.215817' stop-color='%238B6AAE' stop-opacity='0.25'/%3E%3Cstop offset='0.217259' stop-color='%238B6AAE' stop-opacity='0.5'/%3E%3Cstop offset='0.649188' stop-color='%238B6AAE' stop-opacity='0.5'/%3E%3Cstop offset='0.651564' stop-color='%238B6AAE' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%238B6AAE'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
.animate-flicker {
  animation: flickerAnimation 1s infinite;
}

.bg-ellipses {
  background-image: url('https://raw.githubusercontent.com/SamHerbert/SVG-Loaders/5deed925369e57e9c58ba576ce303466984db501/svg-loaders/three-dots.svg');
}

.EZDrawer > label {
  backdrop-filter: blur(5px);
}

/*! Sort Selection */
.sort-selection [type='radio']:checked,
.sort-selection [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.sort-selection [type='radio']:checked + span,
.sort-selection [type='radio']:not(:checked) + span {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.sort-selection [type='radio']:checked + span:before,
.sort-selection [type='radio']:not(:checked) + span:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 0.125rem;
  background: #fff;
}
.sort-selection [type='radio']:checked + span:after,
.sort-selection [type='radio']:not(:checked) + span:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #a988cc;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 0.125rem;
  transition: all 0.2s ease;
}
.sort-selection [type='radio']:not(:checked) + span:after {
  opacity: 0;
  transform: scale(0);
}
.sort-selection [type='radio']:checked + span:after {
  opacity: 1;
  transform: scale(1);
}

/*! Category Selection */
.sort-selection [type='checkbox']:checked,
.sort-selection [type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.sort-selection [type='checkbox']:checked + span,
.sort-selection [type='checkbox']:not(:checked) + span {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.sort-selection [type='checkbox']:checked + span:before,
.sort-selection [type='checkbox']:not(:checked) + span:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 0.125rem;
  background: #fff;
}
.sort-selection [type='checkbox']:checked + span:after,
.sort-selection [type='checkbox']:not(:checked) + span:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #a988cc;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 0.125rem;
  transition: all 0.2s ease;
}
.sort-selection [type='checkbox']:not(:checked) + span:after {
  opacity: 0;
  transform: scale(0);
}
.sort-selection [type='checkbox']:checked + span:after {
  opacity: 1;
  transform: scale(1);
}

/*! Courier Selection */
.courier-selection [type='radio']:checked,
.courier-selection [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.courier-selection [type='radio']:checked + span,
.courier-selection [type='radio']:not(:checked) + span {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.courier-selection [type='radio']:checked + span:after,
.courier-selection [type='radio']:not(:checked) + span:after {
  content: '';
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.940813 5.92694C1.46045 5.40152 2.30296 5.40152 2.8226 5.92693L7.5271 10.6837C8.04674 11.2091 8.04674 12.0609 7.5271 12.5864C7.00746 13.1118 6.16495 13.1118 5.64531 12.5864L0.940819 7.82964C0.421176 7.30422 0.421173 6.45236 0.940813 5.92694Z' fill='%2300CC88'/%3E%3Cpath d='M17.0592 1.0799C17.5789 1.60532 17.5789 2.45718 17.0592 2.9826L7.65027 12.4961C7.13063 13.0215 6.28812 13.0215 5.76848 12.4961C5.24884 11.9707 5.24884 11.1188 5.76848 10.5934L15.1774 1.0799C15.6971 0.554483 16.5396 0.554484 17.0592 1.0799Z' fill='%2300CC88'/%3E%3C/svg%3E%0A");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2px;
  margin: auto;
  border-radius: 0.125rem;
  transition: all 0.2s ease;
}
.courier-selection [type='radio']:not(:checked) + span:after {
  opacity: 0;
  transform: scale(0);
}
.courier-selection [type='radio']:checked + span:after {
  opacity: 1;
  transform: scale(1);
}

body {
  font-family: 'Poppins', sans-serif;
}

/*! Calendar */
.react-calendar {
  width: auto;
  max-width: 100%;
  background: white;
  border: none;
  font-family: 'Poppins', sans-serif;
  line-height: 1.125em;
  &__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
    gap: 0.25rem !important;
  }
  &__month-view__days__day {
    padding: 0.75rem 0.5rem;
    border-color: white !important;
    border-width: 1px !important;
    border-style: solid !important;
    font-size: 0.75rem;
    color: rgba(70, 53, 87, 0.7490196078);
    border-radius: 0.25rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
    &--neighboringMonth {
      color: #cbcbcb !important;
    }
  }
  &__tile--active {
    border-color: #a988cc !important;
    background: white;
    color: #463557bf;
    &:enabled:hover,
    &:enabled:focus {
      background: white;
    }
  }
  &__tile {
    &:disabled {
      color: #46355740 !important;
      background-color: #eeeeee !important;
      border-color: #eeeeee !important;
    }
  }
}

.Toastify__close-button {
  align-self: center;
  margin-right: 0.5rem;
}

.react-date-picker {
  & > .react-date-picker__wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border: none;
    border-bottom: 1.5px solid #d8d8d8;
    & > .react-date-picker__inputGroup {
      padding: 0;
    }
  }
}

.react-time-picker {
  & > .react-time-picker__wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border: none;
    border-bottom: 1.5px solid #d8d8d8;
    & > .react-time-picker__inputGroup {
      padding: 0;
    }
  }
}
.product-category-home .ant-image {
  display: block;
}
